import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import CardINFra01 from 'components/Products/IR_Lighting/inFraCard01.jsx';
import CardINFra02 from 'components/Products/IR_Lighting/inFraMenuCards01.jsx';
import LED903Table from 'components/Products/IR_Lighting/903LEDTable';
import LED905Table from 'components/Products/IR_Lighting/905LEDTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Infrared Floodlights",
  "path": "/Products/IR_Lighting/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The infrared spotlight can be directly plugged into every power socket and has an automatic sensor which automatically turns the spotlight on and off. As soon as it gets dark the IR LEDs will activate and illuminates the area with invisible infrared light.",
  "image": "./P_SearchThumb_InfraredSpotlights.png",
  "social": "/images/Search/P_SearchThumb_InfraredSpotlights.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Route_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Infrared Floodlights' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The infrared spotlight can be directly plugged into every power socket and has an automatic sensor which automatically turns the spotlight on and off. As soon as it gets dark the IR LEDs will activate and illuminates the area with invisible infrared light.' image='/images/Search/P_SearchThumb_InfraredSpotlights.png' twitter='/images/Search/P_SearchThumb_InfraredSpotlights.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IR_Beleuchtung/' locationFR='/fr/Products/IR_Lighting/' crumbLabel="IR Light" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "infrared-spotlights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#infrared-spotlights",
        "aria-label": "infrared spotlights permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Infrared Spotlights`}</h1>
    <h2 {...{
      "id": "in-90x",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-90x",
        "aria-label": "in 90x permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-90X`}</h2>
    <CardINFra01 mdxType="CardINFra01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`A person or car moving into the surveillance area will again reflect a huge amount of the IR light and will register easily. The problem is, that the camera sees mostly black and automatically increases the exposure time of every image "shot" for the live video stream, causing every tiny movement to blur the object. You often end up being unable to recognize a face or car brand, if they don´t stop for a few seconds in front of the camera for you. How do you get around this problem? Easily! Use an HD camera and install a regular floodlight with a motion switch. HD cameras have a much bigger sensor and catching a lot more light - allowing them to use shorter exposure times, which again leads to less motion blur in your images. The also react a lot faster to changes in the light conditions - if you switch on an external light. VGA cameras will be "blinded" for a while, allowing the subject to leave the surveillance area, while you are still looking at a white screen.`}</p>
    <p>{`But what if you don´t need the HD resolution? What if a regular light is to obvious and you need a more discreet surveillance? The IN-900 Series Infrared Spotlights might just be what you need to supplement your existing cameras. Build to the IP65 Standard they can easily be installed, wherever you have a power outlet available. It is even possible to install them closer to the car, door or whatever is the subject of your surveillance, to focus their light to the area that counts. All models work autonomously via a twilight switch / photodiode, only switching themselves on when it gets dark. And they adjust their power output automatically according to the brightness of the surrounding. The don´t need to be wired to a camera.`}</p>
    <h3 {...{
      "id": "in-903-wide-angle--low-range",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#in-903-wide-angle--low-range",
        "aria-label": "in 903 wide angle  low range permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-903 Wide Angle / Low Range`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/afdbe9982d3454982f6c19acb13d9a48/f93b5/IN-90X_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6M5xZ0maW1A1B//EABoQAAMAAwEAAAAAAAAAAAAAAAECAwAQEyH/2gAIAQEAAQUCpWitOlCceiBuqgT8UqDnNNf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAdEAEAAQUAAwAAAAAAAAAAAAABEQACECEiEmGR/9oACAEBAAY/AoLBK6tgxDcD7rlFjW8bCp8LfmP/xAAbEAEBAQEBAAMAAAAAAAAAAAABEQAxUUFhcf/aAAgBAQABPyHxaTdFjDuGl0q50Uyg0CPk4RqL9c3TX6abFvYwAQJv/9oADAMBAAIAAwAAABDjDwD/xAAWEQADAAAAAAAAAAAAAAAAAAARICH/2gAIAQMBAT8QpT//xAAWEQADAAAAAAAAAAAAAAAAAAARICH/2gAIAQIBAT8QgT//xAAbEAEAAgIDAAAAAAAAAAAAAAABABFRgSExQf/aAAgBAQABPxBi8ei9wOLtQq2QADYxX0CVGmOBwAK8DhuKBcqr7YItbORYGgnkFxupWkMBU//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afdbe9982d3454982f6c19acb13d9a48/e4706/IN-90X_01.avif 230w", "/en/static/afdbe9982d3454982f6c19acb13d9a48/e47c0/IN-90X_01.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/afdbe9982d3454982f6c19acb13d9a48/a0b58/IN-90X_01.webp 230w", "/en/static/afdbe9982d3454982f6c19acb13d9a48/c85cb/IN-90X_01.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afdbe9982d3454982f6c19acb13d9a48/e83b4/IN-90X_01.jpg 230w", "/en/static/afdbe9982d3454982f6c19acb13d9a48/f93b5/IN-90X_01.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/afdbe9982d3454982f6c19acb13d9a48/f93b5/IN-90X_01.jpg",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`External infrared spotlight with 60 IR LEDs for a realistic range of approximately 15-20 meter with 80 degree illumination angle. For instance, this infrared spotlight is the perfect companion for the model IN-4010. But you can also use the IN-903 Spotlight with every other cameras in order to flood the captured area with more infrared light.`}</p>
    <p>{`The infrared spotlight can be directly plugged into every power socket and has an automatic sensor which automatically turns the spotlight on and off. As soon as it gets dark the IR LEDs will activate and illuminates the area with invisible infrared light. The power usage of the spotlight is very low, compared to regular light sources, and lies around 6-8 Watt, depending on how dark the surrounding is.`}</p>
    <p>{`Please keep in mind that objects in front of the camera will reflect IR light thus increasing the illumination. The more objects you have in front of the camera the more you will see. If you point the spotlight towards highly diffuse reflective areas, like lawns and greeneries, the amount of light reflected towards the camera will fall off dramatically, resulting in a much darker image.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LED903Table mdxType="LED903Table" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "in-905-small-angle--long-range",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#in-905-small-angle--long-range",
        "aria-label": "in 905 small angle  long range permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-905 Small Angle / Long Range`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be1e389779352b728ad34cc80c4b8198/f93b5/IN-90X_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAak0TJuCQf/EABsQAAMAAgMAAAAAAAAAAAAAAAECAwAEEhMU/9oACAEBAAEFAr2cWlevbj6zGvmrzUOF/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHxAAAgEEAgMAAAAAAAAAAAAAAQIAESFBUQMQEmFx/9oACAEBAAY/AvFGAoNRQ/ICp9dMwYUO5i2ZdgT8n//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFREDH/2gAIAQEAAT8hfEQu7bLHApx59sZxsWJk6Q4dlH//2gAMAwEAAgADAAAAECfP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qh//EAB4QAAICAgIDAAAAAAAAAAAAAAERACExQRBhUXGh/9oACAEBAAE/EDQzEGL31KUABFqrHa4Hl2gRDhBaUJxgCBYXtG68QWeZcH9n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be1e389779352b728ad34cc80c4b8198/e4706/IN-90X_02.avif 230w", "/en/static/be1e389779352b728ad34cc80c4b8198/e47c0/IN-90X_02.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be1e389779352b728ad34cc80c4b8198/a0b58/IN-90X_02.webp 230w", "/en/static/be1e389779352b728ad34cc80c4b8198/c85cb/IN-90X_02.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be1e389779352b728ad34cc80c4b8198/e83b4/IN-90X_02.jpg 230w", "/en/static/be1e389779352b728ad34cc80c4b8198/f93b5/IN-90X_02.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be1e389779352b728ad34cc80c4b8198/f93b5/IN-90X_02.jpg",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`External infrared spotlight with 60 strong IR LEDs for a much longer range of approximately 20-30 meter with a smaller angle of 60 degree illumination. You can use the IN-905 Spotlight with every INSTAR cameras in order to flood the captured area with more infrared light.`}</p>
    <p>{`The infrared spotlight can be directly plugged into every power socket and has an automatic sensor which automatically turns the spotlight on and off. As soon as it gets dark the IR LEDs will activate and illuminates the area with invisible infrared light. The power usage of the spotlight lies a little bit higher than the smaller IN-903 with 6-10 Watts depending on how dark the surrounding is.`}</p>
    <p>{`Please keep in mind that objects in front of the camera will reflect IR light thus increasing the illumination. The more objects you have in front of the camera the more you will see. If you point the spotlight towards highly diffuse reflective areas, like lawns and greeneries, the amount of light reflected towards the camera will fall off dramatically, resulting in a much darker image. This spotlight, if used at a short distance, should be directed towards a wall or the ceiling close to the point of surveillance to use the indirect light to illuminate the area. Using the model IN-905 on short range directly, will lead to a tiny pool of light in the center. The camera will either overexpose this area to resolve the background or vice versa, leading to a background underexposed to black.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LED903Table mdxType="LED903Table" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "how-to-install-the-spotlight",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-install-the-spotlight",
        "aria-label": "how to install the spotlight permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to install the Spotlight`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/0a47e/IN-90X_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABrElEQVQoz31R30vbUBjNX7W3PQwEX/YPyEBB/wPfHI49aykoCNZZfxS1klY3bdIm1qLYNqk1s3dtUWOlOvYyG5u0ubc2xpvcb2jng1N2+OCDA+c7h/NxANDtdrFtY2xj2/Y8DwAOBOFbaG5HEPm16MY6vxXfiIQXzk9OCCGUUtd1KaWMMQ4AjEZD1/WLWq1er1NKAUBMyen9vaqul6rVx6kkdzOk04Hn4ICxh+10wffYE8vH4kJK0hBSj4oFTcupqnpY9H3/hRigQ8gRH81sxvDTbUlOh5eWE0kpkZK3xeTqOl/UNABgjD0Tm5b5/VAVpoOR4MTC4pce22pZCUGcDE59HP88GZyanV8sIQQA/5hzqKQlxa1PY6OyJGzGo83mzd8KMTkuoUAgkFeU1vXvq6ufrzgL21/f97/re/smHJoxjEY+uw8A9J7mlIKiFvgYj/JqNrLyS1Wcdrtpmna7bRiGaZoEY04/Ox0Z+jA8OJA92AOAi9p5Ji3ddggD2E3L5Ur5FhOzfund3fmM3T/CcZzetx4Ksyyr8gO5rtsLYzSuoyuR0Mx0tYzgv/gD7rnP/Q+EHxMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/e4706/IN-90X_03.avif 230w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/d1af7/IN-90X_03.avif 460w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/56a33/IN-90X_03.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/a0b58/IN-90X_03.webp 230w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/bc10c/IN-90X_03.webp 460w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/e88ff/IN-90X_03.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/81c8e/IN-90X_03.png 230w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/08a84/IN-90X_03.png 460w", "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/0a47e/IN-90X_03.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ac4ba11c6a76e7fddf8229f12d2f639/0a47e/IN-90X_03.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1.`}</strong>{` IN-90X IR Spotlight - Component Overview. Powerplug, Wall or Ceiling Mount and the IP65 Casing.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d44724f2705e664945e8344e8c071c11/0a47e/IN-90X_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQ0lEQVQoz52RS07CYBSFuwtGsi32wpAZUxbAAjDEWDSgA4qgCErFFLSFyKulCJGHDKQthH6mEA0gJIaT3PyPc3P+c/8juC6/cDcP/7jz1l1e+JyMyUgSiqIwHA7R9Q71eg3D0Fkul/9+SO/26fYGCKJ4js/nIxgMMp1OSaaShMNhnsvlLRebbn72tu2gdwdU1Ba5QoWq1kAIhUL4T/xEo1G+ZjNuslkikQiapv4R2x1x8DGiUHpBeW2TKyq0Ol2ESrVCIBCg3++vmpbuekzHcTiEyWRCu9Wi1mwSS1xweS2RuZPp6CaC1xCPx+mZ5urPLMvCtqyVoG3bW+Vxi8UC+bFEOi0hpq44FRPEzkRuCzLD0XgtaBgG9/k8T7KMXCodLI9/KBbRVHXl9K3ZwOy94zhz5vP5OuV9CR4LT0vYl+SxBS7fpZGfpEy8vZsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d44724f2705e664945e8344e8c071c11/e4706/IN-90X_04.avif 230w", "/en/static/d44724f2705e664945e8344e8c071c11/d1af7/IN-90X_04.avif 460w", "/en/static/d44724f2705e664945e8344e8c071c11/56a33/IN-90X_04.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d44724f2705e664945e8344e8c071c11/a0b58/IN-90X_04.webp 230w", "/en/static/d44724f2705e664945e8344e8c071c11/bc10c/IN-90X_04.webp 460w", "/en/static/d44724f2705e664945e8344e8c071c11/e88ff/IN-90X_04.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d44724f2705e664945e8344e8c071c11/81c8e/IN-90X_04.png 230w", "/en/static/d44724f2705e664945e8344e8c071c11/08a84/IN-90X_04.png 460w", "/en/static/d44724f2705e664945e8344e8c071c11/0a47e/IN-90X_04.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d44724f2705e664945e8344e8c071c11/0a47e/IN-90X_04.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2.`}</strong>{` Please plug the spotlight power cable to your 110V or 230V power socket.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/0a47e/IN-90X_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrElEQVQoz32Sz04TURTG560qTyF9he4NGxdsuie+gIhbQRLCygY2pJLYqDUKWhO1JogiOG1j5/90Ovfce39m7pQKC/2SLzdnzj3ffOee44kRSl06ilHchsVa+zcSQWc5OsvQSYIkKSqOHcsoQuc5XlamKK0QK8RZzKPHm44bDzYYfP5QCxnjTjUaM/syxCQJtiigYvXD67w/wpupHFvHdJ51aDQaNJtN1u6tMTwb3hKUMMTkM8LzC8Lxb75fXfJwa4sn29vs7O2R+z5errKqM4der0e73abb7bpYI4gWl6+u2ChmMp5w/Oo9w7cDvvb7tFot7q6ucmdlhYOdp3gzqR3+/DUhiFKUKpdvVrnXVtdtVQ6nAacnpxwcPefHlQ8ipN/O6ezvc399ncPdXby5Lvg0POPw6CUXlyNXaEwtMJcCbfSyAzWdUhYFejmyBcKQyB9BPsObxBNen7zjxZs+QRy6SZcyRxlFNbDaYV0nQYCOIkyeo9MUnaRImiJZhvgjssFHvKrQYLAYJ3K9QhWVLuu1WbTs1iZJa7GbrL4t1slberf8Ey5t/3PhBv4ADHGgjJHNsQwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd2a533fc2b9f6855ce080fb63d152ed/e4706/IN-90X_05.avif 230w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/d1af7/IN-90X_05.avif 460w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/56a33/IN-90X_05.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd2a533fc2b9f6855ce080fb63d152ed/a0b58/IN-90X_05.webp 230w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/bc10c/IN-90X_05.webp 460w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/e88ff/IN-90X_05.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd2a533fc2b9f6855ce080fb63d152ed/81c8e/IN-90X_05.png 230w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/08a84/IN-90X_05.png 460w", "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/0a47e/IN-90X_05.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd2a533fc2b9f6855ce080fb63d152ed/0a47e/IN-90X_05.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3.`}</strong>{` Please adjust the installed spotlight so that it points in the same direction as the camera in order to get the best result.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bf097bf9cd2983ac436bb0c9d588344f/0a47e/IN-90X_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB5klEQVQoz42Su4sTURTG50+RtbKzshBLBbMoKAvBdhtLC0UsBJsllaCVzYIWNtZbuBAN5uFjY3aXjJKXbmLem6d5zCSTydzHTzJJ3FK/e++Be87H4bv3fIbUkjWEFkgk2l/Kj/7W2q8vonLdv3ztODDzYNFirlDODGPkDEkdp4gmotiOzcge0Ww3yP3Ikf2ZQWvFqi9aCJxMlkw8QTwcZtrr02nk+RB7iZkO47U6GMmjAzYDm4RCIUzTJBaNEYlE2H+7z5F56CtddFMrld8THwkGAuw8eMhoOuTezm2u3tlg+9E1+pUiRrvfol6r+eR0Ok0yeYBppjlMpcif5FCcKUQIBuUypVrF52dSe2xtX2Tr7mWu3Nxg98VjDFe52BOXmetS+lWiUChQLJ5QrVZptOuo1ZNZ/Z8eDBlPZ/79Xfw1weA5bty6wKXr53n+9D5GrVXny1cTKRXdbpdyuUylUqHdalNv1VkPbalQ0i2V+HT8DSE1lmvxZu8Vu8+ecPT5PU63gzGYDHDcKZ7ymMs5k5lN73eX006TZq+xVLieqieYVqrMBgPkeIy2J36+WSzgNk+ZZ/MYUguE8vDk3D9r6wg/L5aWWdtGa9TUQVk2cmwhxmO84RBvOMKzLIRlYfAf0Jx58V/4A2DEj83tkSN3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf097bf9cd2983ac436bb0c9d588344f/e4706/IN-90X_06.avif 230w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/d1af7/IN-90X_06.avif 460w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/56a33/IN-90X_06.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bf097bf9cd2983ac436bb0c9d588344f/a0b58/IN-90X_06.webp 230w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/bc10c/IN-90X_06.webp 460w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/e88ff/IN-90X_06.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bf097bf9cd2983ac436bb0c9d588344f/81c8e/IN-90X_06.png 230w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/08a84/IN-90X_06.png 460w", "/en/static/bf097bf9cd2983ac436bb0c9d588344f/0a47e/IN-90X_06.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bf097bf9cd2983ac436bb0c9d588344f/0a47e/IN-90X_06.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`4.`}</strong>{` The spotlight should be pointed at bright surfaces to maximise the amount of infrared light reflected to the camera to achieve uniform illumination.`}</p>
    <p><strong parentName="p">{`Be Aware:`}</strong>{`
Please avoid directing the spotlight onto lawns or into open areas that won´t reflect the light. Only light that returns to the camera contributes to the lighting! You will “loose” most of the light in case of highly absorptive (black surfaces) or diffuse reflective (e.g. lawns or trees) objects.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a149eca863bea1e17212dbaf11b92d13/00d43/IN-90X_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAChElEQVQoz42Sy08TURjFR6L+FepSiEtNXLJQo+6QR4ILSKgWkJQNxJVgIkbYaAJBw4Yo4VUTgQTDq1rljba0QEobIIDDtJaWgg5QaCmdmf4Mw0OBjSc5yc29556c79wr8A8SiYTOPawEgzgnnEx7PLjcbtweDzNzc0y5XAQCgVP6QwgnDTVN09c+n4Tl6wAdvf20dPTQ8L6TV2/e8uT5S0a/2XWNqmr/byh5vfQPjWDptzFqm8Ix6cHmcDE4Ms7Coqhr9rSnDA9jH1JVVf3ANu6gp8+CddiJfWKGjXCEX+thloNrBIIrx0be5765njChJXRqqnaUUBQlhodHcDrcjNomEJck/N4As3PzhFZC+wlVjZMQVE05tpEggbwps7G1gaaouGQPS1teFjZFpK2fpwyiu1Hkjd+sh2V247sIkfg2vZ96aG5touJFBVevX+PylWQuXLqIodjA4o6kl7+5G2Z8bZLlkJ8WczMNje8oeVxCVnYWxkIjmdmZDI4NIITkECaTiabGJtLS7iEIAinJKdy5fZenz8o5rISD6b4MWCkqLMLcaiY1NZWc3FyqqqooKS1lbHwMIRqPEIvt6OKHRqNuaCo2Yf1sxWLtQ0M7KB0UTSES29a1kWgEY34+1TU12O12urq7cM+6EaJKhPkfXhQVDIYHumF6RgaVlVXUN9QfvSZ6SA05vM6Sb4VgMEh+QQG1tbU4HA7a2tqYnp1GkLdlJqfd+sU8Q55uePb8Oc4kJXE/J/vUBxb9S4iSj9W1VW7cukl6ZgZl5WUUFj1i+PsQQkzZQSGuU/SJmD+Yqauvo/p1NX3W3r8dHnzkmLqDhkI0HsXmtNHe2Ua3pYuOj+1Ifok/O+8zE414OL4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a149eca863bea1e17212dbaf11b92d13/e4706/IN-90X_07.avif 230w", "/en/static/a149eca863bea1e17212dbaf11b92d13/d1af7/IN-90X_07.avif 460w", "/en/static/a149eca863bea1e17212dbaf11b92d13/7f308/IN-90X_07.avif 920w", "/en/static/a149eca863bea1e17212dbaf11b92d13/445fc/IN-90X_07.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a149eca863bea1e17212dbaf11b92d13/a0b58/IN-90X_07.webp 230w", "/en/static/a149eca863bea1e17212dbaf11b92d13/bc10c/IN-90X_07.webp 460w", "/en/static/a149eca863bea1e17212dbaf11b92d13/966d8/IN-90X_07.webp 920w", "/en/static/a149eca863bea1e17212dbaf11b92d13/a5d4d/IN-90X_07.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a149eca863bea1e17212dbaf11b92d13/81c8e/IN-90X_07.png 230w", "/en/static/a149eca863bea1e17212dbaf11b92d13/08a84/IN-90X_07.png 460w", "/en/static/a149eca863bea1e17212dbaf11b92d13/c0255/IN-90X_07.png 920w", "/en/static/a149eca863bea1e17212dbaf11b92d13/00d43/IN-90X_07.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a149eca863bea1e17212dbaf11b92d13/c0255/IN-90X_07.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`5.`}</strong>{` All spotlights employ integrated lenses on each LED to gain a high operating dinstance. In case that you rather need an even illumination instead of a wide distance, please use a thin leaf of paper or a semi-tranparent foil as a diffuser under the glass cover.`}</p>
    <h2 {...{
      "id": "visible-vs-invisible",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#visible-vs-invisible",
        "aria-label": "visible vs invisible permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Visible vs. Invisible`}</h2>
    <p><em parentName="p">{`Why are my IR LEDs red?`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c109de4ac41cfe897ef1a8697ef10599/00d43/IN-90X_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABX0lEQVQoz31Ry4rCUAzNysXgl7kR8UNEcOP/dOdvuBAFQXQn0kFLlT5v29vX7eMMiY/NDBMISe7j5OSE+r5HbgyKvocqColpUaCqql9eliWUUihe91xzrOtaco5UGQPveITabhHtdog2GziHA66Og2/bhuu6iOMYURRJvFwueDweAsx1GIbwfV/OtNagvO+RTKfoiWCGQ2RE6JZLxADu1yuMMdKZH7dtK5/Z2fg8TVOpPc97AuquQzIePwEHA2gitIsFMgBpFMlITdPI467rhBX7GzDP80+eZRlI1TXCyQQVEfTXFxQRktkMCoDyfRmVwXjEJEkEjGXgRnzONed8x2ypAJCMRk+GRIgYeD6HAVCm6Wcs/sgMWALOGewtBRsvSgBN10Gv18gsC9lqhcCyoPd72bhzu300CoJANslg9/tdGL1Zc6P01ZzwhzW8ENfF+XzG6XSSDdq2LcKzjv/ZD9YlRLg7j8XpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c109de4ac41cfe897ef1a8697ef10599/e4706/IN-90X_09.avif 230w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/d1af7/IN-90X_09.avif 460w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/7f308/IN-90X_09.avif 920w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/445fc/IN-90X_09.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c109de4ac41cfe897ef1a8697ef10599/a0b58/IN-90X_09.webp 230w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/bc10c/IN-90X_09.webp 460w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/966d8/IN-90X_09.webp 920w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/a5d4d/IN-90X_09.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c109de4ac41cfe897ef1a8697ef10599/81c8e/IN-90X_09.png 230w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/08a84/IN-90X_09.png 460w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/c0255/IN-90X_09.png 920w", "/en/static/c109de4ac41cfe897ef1a8697ef10599/00d43/IN-90X_09.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c109de4ac41cfe897ef1a8697ef10599/c0255/IN-90X_09.png",
              "alt": "INSTAR Infrared Floodlights",
              "title": "INSTAR Infrared Floodlights",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Infrared LEDs working at 850nm - invisible to the human eye - still emit a small percentage of light that is visible as a red shimmer.`}</p>
    <p>{`The night vision capability of your INSTAR Camera utilizes infrared light with a wavelength of 850nm. Diodes in this wavelength range still emit some light in the visible range (red) which is the cause of the red gleam that you can see when looking directly into the infrared LEDs. Only the spotlight IN-905 (940) uses LEDs that are completely invisible to the human eye!`}</p>
    <p><strong parentName="p">{`Warning!`}</strong>{`
The application of invisible 940nm diodes comes with the disadvantage of effectively halving the operating range of your spotlight! The cause for this effect is the decreased sensitivity of the imaging sensor, which is falling rapidly with the increasing wavelength in the IR region. Furthermore, there is an increase in the air’s opacity for light in the range around 900nm. The application of a 940nm spotlight is only recommended, `}<strong parentName="p">{`if an invisible illumination of the observed area is deemed necessary`}</strong>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <CardINFra02 mdxType="CardINFra02" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      